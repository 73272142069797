import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Comprador} from "../model/Comprador";
import {tap} from "rxjs/operators";
import {Proveedor} from "../model/proveedor";


@Injectable()
export class CompradorService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'comprador';
    }
    getLista(): Observable<Lista<Comprador>> {
        return this.http.get<Lista<Comprador>>(this.api+'?orderBy='+encodeURI('nombre')+
            '&orderDir='+encodeURI('asc'));
    }
    getListaFiltrada(ejemplo: Comprador): Observable<Lista<Comprador>> {
        return this.http.get<Lista<Comprador>>(this.api+'?orderBy='+encodeURI('nombre')+
            '&orderDir='+encodeURI('asc')+'&ejemplo='+encodeURI(JSON.stringify(ejemplo))+
            '&like=S');
    }

    get(id: number): Observable<Comprador> {
        return this.http.get<Comprador>(this.api+'/'+id);
    }

    getPorCodigo(codigo: string): Observable<Comprador> {
        return this.http.get<Comprador>(this.api+'/codigo/'+codigo);
    }

    agregar(p: Comprador): Observable<Comprador>{
        return this.http.post<Comprador>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: Comprador): Observable<Comprador>{
        return this.http.put<Comprador>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }
}
