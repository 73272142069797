import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Comprador} from "../model/Comprador";
import {tap} from "rxjs/operators";
import {CompradorLugarEntrega} from "../model/comprador-lugar-entrega";


@Injectable()
export class CompradorLugaresService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'compradorLugarEntrega';
    }
    getLista(ejemplo: CompradorLugarEntrega): Observable<Lista<CompradorLugarEntrega>> {
        return this.http.get<Lista<CompradorLugarEntrega>>(this.api+
            '?orderBy='+encodeURI('nombre')+
            '&orderDir='+encodeURI('asc')+
            '&ejemplo='+encodeURI(JSON.stringify(ejemplo))+
            '&like=S');
    }

    get(id: number): Observable<CompradorLugarEntrega> {
        return this.http.get<CompradorLugarEntrega>(this.api+'/'+id);
    }

    agregar(p: CompradorLugarEntrega): Observable<CompradorLugarEntrega>{
        return this.http.post<CompradorLugarEntrega>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: CompradorLugarEntrega): Observable<CompradorLugarEntrega>{
        return this.http.put<CompradorLugarEntrega>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }
}
