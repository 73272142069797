import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lista} from '../model/lista';
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {tap} from "rxjs/operators";

import {CompradorCapacidadRecepcion} from '../model/comprador-capacidad-recepcion';


@Injectable()
export class CompradorCapacidadRecepcionService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'compradorCapacidadRecepcion';
    }
    getLista(ejemplo: CompradorCapacidadRecepcion): Observable<Lista<CompradorCapacidadRecepcion>> {
        return this.http.get<Lista<CompradorCapacidadRecepcion>>(this.api+
            '?ejemplo='+encodeURI(JSON.stringify(ejemplo))+
            '&orderBy='+encodeURI('fechaExcepcion')+
            '&orderDir='+encodeURI('asc')+
            '&like=S');
    }

    get(id: number): Observable<CompradorCapacidadRecepcion> {
        return this.http.get<CompradorCapacidadRecepcion>(this.api+'/'+id);
    }

    agregar(p: CompradorCapacidadRecepcion): Observable<CompradorCapacidadRecepcion>{
        return this.http.post<CompradorCapacidadRecepcion>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: CompradorCapacidadRecepcion): Observable<CompradorCapacidadRecepcion>{
        return this.http.put<CompradorCapacidadRecepcion>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }
}
