import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import {UserActive} from "../../@core/data/user-activity";
import {Userlogin} from "../../model/userlogin";
import {Usuario} from "../../model/usuario";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService,
        private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //return next.handle(req);
        const user: Usuario = this.authService.getCurrentUser();

        if (user && user.token) {


            const cloned = req.clone({
                headers: req.headers.set('Authorization',
                    'Bearer ' + user.token)
                    .set('usuario', user.usuario),

            });

            return next.handle(cloned).pipe(tap(() => { }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {

                        this.router.navigate(['/auth/login']);
                    }
                }
            }));

        } else {
            return next.handle(req);
        }
    }
}
