import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Comprador} from "../model/Comprador";
import {tap} from "rxjs/operators";
import {Calendario} from "../model/calendario";
import {CalendarioResumen} from "../model/calendario-resumen";
import {id} from "@swimlane/ngx-charts";
import {DetalleTurnos} from "../model/detalle-turnos";
import {Documentos} from "../model/documentos";
import {CalendarioPedidoCabecera} from "../model/calendario-pedido-cabecera";


@Injectable()
export class CalendarioService {
    private api: string;
    constructor(private http: HttpClient) {
        this.api=Utiles.api+'calendario';
    }
    getListaTurnos(idComprador: number, fecha: string): Observable<Calendario[]> {
        return this.http.get<Calendario[]>(this.api+'/turnos?idComprador='+idComprador+
            '&fecha='+fecha);
    }

    getDocumentos(idComprador: number, idProveedor: number): Observable<Documentos> {
        return this.http.get<Documentos>(this.api+'/documentos?idComprador='+idComprador+
            '&idProveedor='+idProveedor);
    }

    getPlantillaTurnos(fecha: string): Observable<Calendario[]> {
        return this.http.get<Calendario[]>(this.api+'/plantilla?fecha='+fecha);
    }

    getListaTurnosSinLibres(idComprador: number, fecha: string): Observable<Calendario[]> {
        return this.http.get<Calendario[]>(this.api+'/turnos?idComprador='+idComprador+
            '&fecha='+fecha+'&soloConfirmados=S');
    }

    getListaTurnosSinLibresDelProveedor(idProveedor: number, fecha: string): Observable<Calendario[]> {
        return this.http.get<Calendario[]>(this.api+'/turnos?idProveedor='+idProveedor+
            '&fecha='+fecha+'&soloConfirmados=S&incluirTodos=S');
    }
    getListaTurnosDelProveedor(idProveedor: number, fecha: string, idComprador: number, soloRegistrados: string): Observable<Calendario[]> {
        return this.http.get<Calendario[]>(this.api+'/turnos?idProveedor='+idProveedor+
            '&fecha='+fecha+'&incluirTodos=S'+'&incluirTurnosOcupadosJaulas=S' +
            '&soloRegistrados='+ soloRegistrados +
        (idComprador!=null?'&idComprador='+idComprador:''));
    }

    getListaTurnosPendientesAprobados(fecha: string, horaInicio: string): Observable<Calendario[]> {
        return this.http.get<Calendario[]>(this.api+'/turnos?'+
            '&fecha='+fecha+'&horaInicio='+horaInicio+'&estados=A;;P');
    }

    getCalendarioResumen(idComprador: number, mes: number, anho: number): Observable<CalendarioResumen[]> {
        return this.http.get<CalendarioResumen[]>(this.api+'/calendario?idComprador='+idComprador+
            '&mes='+mes+'&anho='+anho);
    }

    get(id: number): Observable<Comprador> {
        return this.http.get<Comprador>(this.api+'/'+id);
    }

    agregar(cal: Calendario): Observable<Calendario>{
        return this.http.post<Calendario>(this.api,cal)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    marcarIniciado(idCalendario:number, idCompradorLugarEntrega,
                   idUsuarioIniciador: number,
                   pedidoCabecera: CalendarioPedidoCabecera): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/inicioRecepcion/'+idCalendario+'/'+idCompradorLugarEntrega+'/'+idUsuarioIniciador,
                pedidoCabecera)
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como iniciado '+data),
                    error => console.log('error al marcar como iniciado: '+error)
                )
            );
    }

    cambiarHoraInicio(idCalendario:number, horaInicio: string,
                      horaFin: string, duracion: string, motivo: string,
                      idUsuarioModificador: number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/horaInicio/'+idCalendario+'/'+idUsuarioModificador,
                {motivoCambioTurno:horaInicio+';;'+horaFin+';;'+duracion+';;'+motivo})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como iniciado '+data),
                    error => console.log('error al marcar como iniciado: '+error)
                )
            );
    }
    cambiarHoraFinDuracionAprobar(idCalendario:number, horaFin: string,
                      duracion: string, motivo: string, devolucion: string, idUsuarioModificador: number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/horaFinDuracionAprobar/'+idCalendario+'/'+idUsuarioModificador,
                {motivoCambioTurno:horaFin+';;'+duracion+';;'+motivo+';;'+devolucion})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como iniciado '+data),
                    error => console.log('error al marcar como iniciado: '+error)
                )
            );
    }

    marcarVerificacion(idCalendario:number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/inicioVerificacion/'+idCalendario,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como iniciada la verificacion '+data),
                    error => console.log('error al marcar como iniciada la verificacion: '+error)
                )
            );
    }

    marcarFinalizado(idCalendario:number, idUsuarioFinalizador: number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/finRecepcion/'+idCalendario+'/'+idUsuarioFinalizador,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como finalizado '+data),
                    error => console.log('error al marcar como finalizado: '+error)
                )
            );
    }
    marcarFinDevolucion(idCalendario:number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/finDevolucion/'+idCalendario,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como finalizado '+data),
                    error => console.log('error al marcar como finalizado: '+error)
                )
            );
    }
    cancelarTurno(idCalendario:number, motivo: string): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/cancel/'+idCalendario+'?motivo='+motivo,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como cancelado '+data),
                    error => console.log('error al marcar como cancelado: '+error)
                )
            );
    }
    aceptarTurno(idCalendario:number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/aceptar/'+idCalendario,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como aceptado '+data),
                    error => console.log('error al marcar como aceptado: '+error)
                )
            );
    }
    rechazarTurno(idCalendario:number, motivo: string): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/rechazar/'+idCalendario+'?motivo='+motivo,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como rechazado '+data),
                    error => console.log('error al marcar como rechazado: '+error)
                )
            );
    }
    marcarLlegada(idCalendario:number): Observable<Calendario> {
        return this.http
            .put<Calendario>(
                this.api+'/llegada/'+idCalendario,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado la llegada '+data),
                    error => console.log('error al marcar la llegada: '+error)
                )
            );
    }

    levantarExcel(formData: FormData, idComprador: number, nombre:string): Observable<any> {
        var cadenaEnviar=this.api+'/upload?nombre='+nombre+'&idComprador='+idComprador;

        return this.http
            .post(cadenaEnviar,formData);

    }

    obtenerDetallesTurnosJaulas(idComprador: number, fecha: string,
                                horaInicioEstimada: string, horaFinEstimada: string): Observable<DetalleTurnos> {
        return this.http.get<DetalleTurnos>(this.api+'/cantidadTurnos?idComprador='+idComprador+
            '&fecha='+fecha+'&horaInicioEstimada='+horaInicioEstimada+'&horaFinEstimada='+horaFinEstimada);
    }

    descargarReporteTurnosExcel(fechaDesde: string, fechaHasta: string,
                                idComprador: number, idProveedor: number): Observable<any> {
        var url= this.api + '/estadisticaturnos/reporte/excel?fechaDesde='+fechaDesde+'&fechaHasta='+fechaHasta;
        if (idComprador!=null) {
            url=url+'&idComprador='+idComprador;
        }
        if (idProveedor!=null) {
            url=url+'&idProveedor='+idProveedor;
        }
        return this.http.get(url
            , {
                observe:'response',responseType:'blob'
            })
            .pipe(
                tap( // Log the result or error

                    data => console.log('marcado como leido '+data),
                    error => console.log('error: '+ error)
                )
            );
    }
}
