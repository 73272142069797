import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Persona} from "../model/persona";
import {tap} from "rxjs/operators";
import {Calendario} from "../model/calendario";

@Injectable()
export class PersonaService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'persona';
    }
    getLista(): Observable<Lista<Persona>> {

        return this.http.get<Lista<Persona>>(this.api+'?orderBy='+encodeURI('apellido')+
        '&orderDir='+encodeURI('asc'));
    }

    get(id: number): Observable<Persona> {
        return this.http.get<Persona>(this.api+'/'+id);
    }

    agregar(p: Persona): Observable<Persona>{
        return this.http.post<Persona>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: Persona): Observable<Persona>{
        return this.http.put<Persona>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }

    aprobarContrato(idPersona:number, email: string): Observable<Persona> {
        return this.http
            .put<Persona>(
                this.api+'/aprobarContrato/'+idPersona+'?email='+email,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('contrato aprobado '+data),
                    error => console.log('contrato error: '+error)
                )
            );
    }
    rechazarContrato(idPersona:number): Observable<Persona> {
        return this.http
            .put<Persona>(
                this.api+'/rechazarContrato/'+idPersona,
                {})
            .pipe(
                tap( // Log the result or error

                    data => console.log('contrato rechazado '+data),
                    error => console.log('contrato error: '+error)
                )
            );
    }
}
