import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Comprador} from "../model/Comprador";
import {tap} from "rxjs/operators";
import {CompradorLugarEntrega} from "../model/comprador-lugar-entrega";
import {ProveedorRubro} from "../model/proveedor-rubro";


@Injectable()
export class ProveedorRubroService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'proveedorRubro';
    }
    getLista(ejemplo: ProveedorRubro): Observable<Lista<ProveedorRubro>> {
        return this.http.get<Lista<ProveedorRubro>>(this.api+
            '?orderBy='+encodeURI('rubro')+
            '&orderDir='+encodeURI('asc')+
            '&ejemplo='+encodeURI(JSON.stringify(ejemplo))+
            '&like=S');
    }

    get(id: number): Observable<ProveedorRubro> {
        return this.http.get<ProveedorRubro>(this.api+'/'+id);
    }

    agregar(p: ProveedorRubro): Observable<ProveedorRubro>{
        return this.http.post<ProveedorRubro>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: ProveedorRubro): Observable<ProveedorRubro>{
        return this.http.put<ProveedorRubro>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }
}
