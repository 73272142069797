import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Persona} from "../model/persona";
import {tap} from "rxjs/operators";
import {ExcepcionFechaDia} from "../model/excepcion-fecha-dia";

@Injectable()
export class FechasDiasExcepService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'excepcionFechaDia';
    }
    getLista(): Observable<Lista<ExcepcionFechaDia>> {

        return this.http.get<Lista<ExcepcionFechaDia>>(this.api+'?orderBy='+encodeURI('fecha')+
        '&orderDir='+encodeURI('desc'));
    }

    get(id: number): Observable<ExcepcionFechaDia> {
        return this.http.get<ExcepcionFechaDia>(this.api+'/'+id);
    }

    agregar(p: ExcepcionFechaDia): Observable<ExcepcionFechaDia>{
        return this.http.post<ExcepcionFechaDia>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: ExcepcionFechaDia): Observable<ExcepcionFechaDia>{
        return this.http.put<ExcepcionFechaDia>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }
}
