import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import {of, EMPTY, Observable} from 'rxjs';
import {Utiles} from "../../pages/utiles/utiles";
import {Userlogin} from "../../model/userlogin";
import {Persona} from "../../model/persona";
import {Usuario} from "../../model/usuario";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private api: string;
    constructor(private http: HttpClient
        ) {
        this.api=Utiles.api+'persona';
    }
    loginDef(usuario: Userlogin): Observable<Usuario> {

        return this.http
            .post<Usuario>(this.api + "/login", usuario);
    }

    // login(email: string, password: string) {
    //     return of(true)
    //         .pipe(delay(1000),
    //             map((/*response*/) => {
    //                 // set token property
    //                 // const decodedToken = jwt_decode(response['token']);
    //
    //                 // store email and jwt token in local storage to keep user logged in between page refreshes
    //                 localStorage.setItem('currentUser', JSON.stringify({
    //                     token: 'aisdnaksjdn,axmnczm',
    //                     isAdmin: true,
    //                     email: 'john.doe@gmail.com',
    //                     id: '12312323232',
    //                     alias: 'john.doe@gmail.com'.split('@')[0],
    //                     expiration: moment().add(1, 'days').toDate(),
    //                     fullName: 'John Doe'
    //                 }));
    //
    //                 return true;
    //             }));
    // }

    logout(): void {
        // clear token remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    getCurrentUser(): any {
        // TODO: Enable after implementation
        var userInfo=localStorage.getItem('currentUser');

        if (userInfo != null && userInfo != '') {
            return JSON.parse(userInfo);
        }
        return null;
        /*return {
            token: 'aisdnaksjdn,axmnczm',
            isAdmin: true,
            email: 'john.doe@gmail.com',
            id: '12312323232',
            alias: 'john.doe@gmail.com'.split('@')[0],
            expiration: moment().add(1, 'days').toDate(),
            fullName: 'John Doe'
        };*/
    }


}
