import {Usuario} from '../../model/usuario';

export class Utiles {
    static readonly modoDebug = false;
    static readonly api = '/agendador/';
    static usuarioLogueado: Usuario;
    static meses=[
        {'mes':'Enero', 'id':1},
        {'mes':'Febrero','id':2},
        {'mes':'Marzo','id':3},
        {'mes':'Abril','id':4},
        {'mes':'Mayo','id':5},
        {'mes':'Junio','id':6},
        {'mes':'Julio','id':7},
        {'mes':'Agosto','id':8},
        {'mes': 'Septiembre','id':9},
        {'mes': 'Octubre','id':10},
        {'mes':'Noviembre','id':11},
        {'mes':'Diciembre','id':12},
    ];
    static diasDeLaSemana=[
        {'dia':'Lunes', 'id':1},
        {'dia':'Martes','id':2},
        {'dia':'Miércoles','id':3},
        {'dia':'Jueves','id':4},
        {'dia':'Viernes','id':5},
        {'dia':'Sábado','id':6},
        {'dia':'Domingo', 'id':0},
    ];

}

