import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import * as moment from 'moment';

import { AuthenticationService } from '../services/auth.service';
import {Toast} from "../../pages/utiles/toast";
import {NbToastrService} from "@nebular/theme";

@Injectable()
export class AuthGuard implements CanActivate {
    toast=new Toast(this.toastrService);
    constructor(private router: Router,
                private toastrService: NbToastrService,
        private authService: AuthenticationService) { }

    canActivate() {

        const user = this.authService.getCurrentUser();

        if (user && user.expiration) {

            if (moment() < moment(user.expiration)) {
                return true;
            } else {
                localStorage.removeItem('savedUsername');
                localStorage.removeItem('currentUser');

                this.router.navigate(['auth/login']);
                return false;
            }
        }

        this.router.navigate(['auth/login']);
        return false;
    }
}
