import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Lista} from "../model/lista";
import {Utiles} from '../pages/utiles/utiles';
// tslint:disable-next-line:quotemark
import {Proveedor} from "../model/Proveedor";
import {tap} from "rxjs/operators";
import {CompradorLugarEntrega} from "../model/comprador-lugar-entrega";
import {ProveedorSeleccionable} from "../model/proveedor-seleccionable";

@Injectable()
export class ProveedorService {
    private api: string;
    constructor(private http: HttpClient) {
// tslint:disable-next-line:whitespace
        this.api=Utiles.api+'proveedor';
    }
    getLista(): Observable<Lista<Proveedor>> {
        return this.http.get<Lista<Proveedor>>(this.api+'?orderBy='+encodeURI('nombre')+
            '&orderDir='+encodeURI('asc'));
    }

    getListaRelacionados(idsRemoto: string): Observable<ProveedorSeleccionable[]> {
        return this.http.get<ProveedorSeleccionable[]>(this.api+'/idPersonaRemoto/ids'+'?idsRemoto='+idsRemoto);
    }

    getListaFiltrada(ejemplo: Proveedor): Observable<Lista<Proveedor>> {
        return this.http.get<Lista<Proveedor>>(this.api+'?orderBy='+encodeURI('nombre')+
            '&orderDir='+encodeURI('asc')+'&ejemplo='+encodeURI(JSON.stringify(ejemplo))+
        '&like=S');
    }

    get(id: number): Observable<Proveedor> {
        return this.http.get<Proveedor>(this.api+'/'+id);
    }

    agregar(p: Proveedor): Observable<Proveedor>{
        return this.http.post<Proveedor>(this.api,p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    modificar(p: Proveedor): Observable<Proveedor>{
        return this.http.put<Proveedor>(this.api, p)
            .pipe(
                tap(
                    data => console.log('agregado: '+data),
                    error => console.log('error: '+error)
                ));
    }

    eliminar(id: number): Observable<{}>{

        return this.http.delete(this.api+'/'+id)
            .pipe(
                tap(
                    data => console.log('eliminado'),
                    error => console.log('eliminado'),
                ));


    }
}
