import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbGlobalPosition,
    NbToastrConfig,
    NbToastrService
} from "@nebular/theme";

export class Toast {
    constructor(private toastrService: NbToastrService) {

    }
    makeToastWarning(titulo: string, contenido: string) {
        this.showToast(this.types[3], titulo, contenido);
    }
    makeToastSuccess(titulo: string, contenido: string) {
        this.showToast(this.types[1],  titulo, contenido);
    }
    makeToastError(titulo: string, contenido: string) {
        this.showToast(this.types[4],  titulo, contenido);
    }

    private showToast(type: NbComponentStatus, title: string, body: string) {
        const config = {
            status: type,
            destroyByClick: this.destroyByClick,
            duration: this.duration,
            hasIcon: this.hasIcon,
            position: this.position,
            preventDuplicates: this.preventDuplicates,
        };
        const titleContent = title ;

        this.index += 1;
        this.toastrService.show(
            body,
            titleContent,
            config);
    }
    config: NbToastrConfig;

    index = 1;
    destroyByClick = true;
    duration = 5000;
    hasIcon = true;
    position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    preventDuplicates = false;
    status: NbComponentStatus = 'primary';

    title = 'HI there!';
    content = `I'm cool toaster!`;
    types: NbComponentStatus[] = [
        'primary',
        'success',
        'info',
        'warning',
        'danger',
    ];
}
